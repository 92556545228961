import React from 'react'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import { MappedImage } from '../Image'

export const Header = ({
  headerNavItem,
  primaryLogo,
  secondaryLogo,
  headerPrimaryLogo,
  headerSecondaryLogo,
}) => (
  <Container as="header" fluid className="header-outer p-sm-1 p-md-3">
    <Row className="header-inner mx-auto px-sm-1 px-md-4">
      <Col sm={12} md={12} lg={12} xl={4} className="d-flex header-logo-outer">
        <div className="header-logo-inner">
          <a href="/">
            {(headerPrimaryLogo || primaryLogo) && (
              <MappedImage
                image={headerPrimaryLogo || primaryLogo}
                style={{ paddingRight: '2rem' }}
                alt={`${headerPrimaryLogo?.title || primaryLogo?.title}`}
              />
            )}
          </a>
          {(headerSecondaryLogo || secondaryLogo) && (
            <MappedImage
              image={headerSecondaryLogo || secondaryLogo}
              alt={`${headerSecondaryLogo?.title || secondaryLogo?.title}`}
            />
          )}
        </div>
      </Col>
      <Col
        sm={12}
        md={12}
        lg={12}
        xl={8}
        className="d-flex justify-content-end pr-4"
      >
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                {headerNavItem &&
                  headerNavItem.map(item => {
                    return (
                      <Nav.Link
                        key={item.navigationTitle}
                        href={item.navigationLink}
                      >
                        {item.navigationTitle}
                      </Nav.Link>
                    )
                  })}
                {/* {languageOptions && (
                  <NavDropdown
                    title={languageSelectorText}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item href={`/en-US/${slug}`}>
                      {languageOptions[0]}
                    </NavDropdown.Item>
                    <NavDropdown.Item href={`/es-US/${slug}`}>
                      {languageOptions[1]}
                    </NavDropdown.Item>
                  </NavDropdown>
                )} */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Col>
    </Row>
  </Container>
)

export const mapHeaderProps = props => {
  const {
    headerNavItem,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  } = props
  return {
    headerNavItem,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  }
}
